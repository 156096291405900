import { RouteNames } from '@constants';
import { AppRouteMapped, AppRoutes } from '@typings';

import attachments from './attachments';
import home from './home';
// import login from './login';
import notFound from './notFound';
import scans from './scans';
import workspace from './workspace';

const routes: AppRoutes = {
    // Home
    [RouteNames.home]: {
        path: '/',
        exact: true,
        auth: true,
        ...home,
    },

    // TODO remove when LoginPage to Prod
    // // Login
    // [RouteNames.login]: {
    //     path: '/login',
    //     exact: true,
    //     auth: false,
    //     ...login,
    // },

    // Workspace Page
    [RouteNames.workspace]: {
        path: '/workspaces/:workspaceId',
        exact: true,
        auth: true,
        ...workspace,
    },

    [RouteNames.scans]: {
        path: '/scans/:scanId',
        exact: true,
        auth: true,
        ...scans,
    },

    [RouteNames.attachments]: {
        path: '/attachments/:attachmentId',
        exact: true,
        auth: true,
        ...attachments,
    },

    // 404
    [RouteNames.defaultPage]: {
        path: '*',
        ...notFound,
    },
};

/*
    ENUM SORTING `RouteNames` DEFINE PRIORITY IN ROUTE MATCHING
*/
const routesMap: AppRouteMapped[] = Object.values(RouteNames).map((id) => ({ ...routes[id], id }));

export { routes, routesMap };
