import { FC } from 'react';

import locales from '@humans-sdk/core/utils/locales';

import { theme } from '@constants';
import { ErrorBoundry, I18nextProvider, Router, SnackbarProvider, ThemeProvider } from '@containers';

import s from './App.module.scss';

const App: FC = () => {
    return (
        <I18nextProvider i18n={locales}>
            <ThemeProvider theme={theme}>
                <ErrorBoundry>
                    <SnackbarProvider>
                        <div className={s.appWrapper}>
                            <Router />
                        </div>
                    </SnackbarProvider>
                </ErrorBoundry>
            </ThemeProvider>
        </I18nextProvider>
    );
};

export default App;
