/*
    WARNING: ENUM SORTING DEFINE PRIORITY IN ROUTE MATCHING
*/
export enum RouteNames {
    // login = 'login',
    home = 'home',
    scans = 'scans',
    attachments = 'attachments',
    workspace = 'workspace',
    defaultPage = 'defaultPage',
}

export default RouteNames;
